import React from "react";
import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "./useAuth";
import AdminHeader from "../components/Header/AdminHeader";

export const ProtectedLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();

  if (!user) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <AdminHeader />
      {outlet}
    </div>
  );
};
